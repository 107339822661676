import * as React from 'react';
import { useRecordContext } from 'react-admin';
import humanizeDuration from 'humanize-duration';

export const DurationField = ({}: {
  label?: string;
  source?: string;
  sortable?: boolean;
}) => {
  const record: any = useRecordContext();
  if (!record || !record?.duration) {
    return null;
  }
  return (
    <span>
      {humanizeDuration(record?.duration * 1000, {
        units: ['h', 'm'],
        round: true,
      })}
    </span>
  );
};

// EpisodesField.defaultProps = {
//   textAlign: 'right',
// };
