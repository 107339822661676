import { fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

const httpClient = (url: URL, options: any = {}) => {
  options.credentials = 'include';
  return fetchUtils.fetchJson(url, options);
};

const baseDataProvider = simpleRestProvider(
  'https://wetvc.saas.tozorlabs.com:3101/api',
  httpClient
);

export const dataProvider = {
  ...baseDataProvider,
};
