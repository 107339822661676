import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { useRecordContext, useUpdate } from 'react-admin';

import MdlItemCard from './MdlItemCard';
import { MdlItemInfo, MdlSearchResponse } from './types';

type MdlLinkDialogProps = {
  open: boolean;
  onClose: () => void;
};

export default function MdlLinkDialog({ open, onClose }: MdlLinkDialogProps) {
  const record: any = useRecordContext();
  const [query, setQuery] = React.useState<string>('');
  const [search, { data }] = useUpdate('mdl_search');
  const [putMdl, { data: mdlResponse }] = useUpdate('products');
  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleSearch = () => {
    search('mdl_search', {
      id: record.id,
      data: { query },
    });
  };

  const handleSelect = (info: MdlItemInfo) => {
    putMdl('products', {
      id: record.id,
      data: { mdl_info: info },
    });
  };
  const respItems = !!data ? (data as unknown as MdlSearchResponse).items : [];
  if (mdlResponse) {
    handleClose();
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Searching MDL link</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Input MDL link address or search query keywords for series.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="mdlSearch"
            label="Link or search query"
            type="text"
            fullWidth
            variant="standard"
            value={query}
            onChange={handleChange}
          />
          <Button disabled={query.length < 3} onClick={handleSearch}>
            Search on MDL
          </Button>
          <>
            {respItems.map((info) => (
              <MdlItemCard
                key={info.title}
                info={info}
                onSelect={handleSelect}
              />
            ))}
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
