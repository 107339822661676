import './App.css';

import { Admin, Resource } from 'react-admin';

import { dataProvider } from './api/dataProvider';
import { authProvider } from './auth/authProvider';
import LoginPage from './auth/LoginPage';
import { MyLayout } from './layout/MyLayout';
import ProductsDetail from './products/detail/ProductsDetail';
import ProductsList from './products/listing/ProductsList';

function App() {
  return (
    <Admin
      dataProvider={dataProvider}
      layout={MyLayout}
      loginPage={LoginPage}
      authProvider={authProvider}
      requireAuth
    >
      <Resource
        name="products"
        list={ProductsList}
        show={ProductsDetail}
        recordRepresentation={(record) => `${record.title}`}
      />
    </Admin>
  );
}

export default App;
