import * as React from 'react';
import { useRecordContext, TopToolbar } from 'react-admin';

import { FollowButton } from './FollowButton';
import { AdditionalMdlButton } from '../../mdl/AdditionalMdlButton';

export const ProductsDetailActions = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  return (
    <TopToolbar>
      <AdditionalMdlButton record={record} />
      <FollowButton record={record} />
    </TopToolbar>
  );
};
