import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { MdlAppendButton } from './MdlAppendButton';
import { ProductItem } from '../products/types';

export const MdlUrlField = ({}: { label: string }) => {
  const record: any = useRecordContext<ProductItem>();
  const items = [];
  if (record?.mdlTitles) {
    for (let i = 0; i < record?.mdlTitles.length; i++) {
      items.push({
        title: record?.mdlTitles[i],
        link: record?.mdlUrls[i],
        index: i,
      });
    }
  }
  return (
    <>
      {record?.mdlTitles ? (
        items.map((item) => (
          <>
            <a key={item.index} href={`${item.link}`} target="_blank">
              {item.title}
            </a>
            <br />
          </>
        ))
      ) : record?.mdlUrl ? (
        <a href={`${record.mdlUrl}`} target="_blank">
          {record.mdlTitle}
        </a>
      ) : (
        <MdlAppendButton />
      )}
    </>
  );
};
