import * as React from 'react';
import Alert from '@mui/material/Alert';
import { useRecordContext } from 'react-admin';

export const LgtvWarningField = ({}: { label: string }) => {
  const record: any = useRecordContext();
  if (record.is_lgbt) {
    return (
      <Alert severity="error">
        This content marked as LGBTQ+ content. Encoding such content is
        prohibited
      </Alert>
    );
  } else {
    return null;
  }
};
