import * as React from 'react';
import { useState } from 'react';
import { Button, useUpdate, Confirm } from 'react-admin';

export const FollowButton = ({ record }: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [follow, { isLoading }] = useUpdate('products', {
    id: record.id,
    data: { isFollowed: record?.is_followed ? false : true },
    previousData: record,
  });
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    follow();
    setOpen(false);
  };
  return (
    <>
      <Button
        label={record?.is_followed ? 'Unfollow' : 'Follow'}
        onClick={handleClick}
        disabled={isLoading}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Change following state"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        content={
          record?.is_followed
            ? 'Are you sure you want to stop following'
            : 'Are you sure you want to start following'
        }
      />
    </>
  );
};
