import { AuthProvider, UserIdentity } from 'react-admin';

import { UserInfo } from './types';
import redirectUri from './discord_consts';
import axios from 'axios';

const authApiUri = 'https://wetvc.saas.tozorlabs.com:3101/api/auth';

export const authProvider: AuthProvider = {
  login: async (param = {}) => {
    console.log(`=== login: ${param}`);
    if (!param || !param.state) {
      window.location.href = redirectUri;

      return Promise.reject({
        message: 'Retrieving code from authentication service.',
        code: 'oauthRedirect',
      });
    }

    // const { status: status1, data: data1 } = await axios.get(`https://listing-api.saas.tozorlabs.com/api/auth/me`, { withCredentials: true});
    // if (status1 === 200) {
    //   console.log('Me right away got');
    //   localStorage.setItem('token', JSON.stringify(data1));
    //   return Promise.resolve();
    // }

    const { status: statusUpdate } = await axios.get(`${authApiUri}/update`, {
      withCredentials: true,
    });
    if (statusUpdate !== 204) {
      return Promise.reject({
        message: 'Incorrect update jwt->auth',
        code: 'jwtAuth',
      });
    }
    const { status: statusRemove } = await axios.get(
      `${authApiUri}/remove_parent`,
      { withCredentials: true }
    );
    if (statusRemove !== 204) {
      return Promise.reject({
        message: 'Incorrect remove jwt',
        code: 'jwtRemove',
      });
    }
    const { status, data } = await axios.get(`${authApiUri}/me`, {
      withCredentials: true,
    });
    if (status !== 200) {
      return Promise.reject({ message: 'Incorrect auth token', code: 'token' });
    }
    localStorage.setItem('token', JSON.stringify(data));
    return Promise.resolve();
  },
  logout: () => {
    console.log('=== logout');
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkError: (error) => {
    // console.log('=== checkError');
    const { status } = error;

    if (status && (status === 401 || status === 403)) {
      localStorage.clear();

      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem('token');

    if (!token) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  getPermissions: () => {
    const token = localStorage.getItem('token');

    return token
      ? Promise.resolve(JSON.parse(token) as UserInfo)
      : Promise.reject();
  },
  getIdentity: () => {
    const token = localStorage.getItem('token');
    if (token) {
      const userInfo: UserInfo = JSON.parse(token);
      const user: UserIdentity = {
        id: userInfo.userId,
        fullName: userInfo.fullUsername,
        avatar: `https://cdn.discordapp.com/avatars/${userInfo?.userId}/${userInfo?.avatar}.jpg`,
      };
      return Promise.resolve(user);
    } else {
      return Promise.reject();
    }
  },
};
