import * as React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  RichTextField,
  ImageField,
  ReferenceManyField,
  Datagrid,
  Pagination,
} from 'react-admin';

import { WeTVUrlField } from './WeTVUrlField';
import { ProductsDetailActions } from './ProductsDetailActions';
import { MdlUrlField } from '../../mdl/MdlDetailUrl';
import { LgtvWarningField } from './LgtvWarningField';
import { DurationField } from './DurationField';
import { EpisodeTitleUrlField } from './EpisodeTitleUrlField';
import { ProductItem } from '../types';

const ProductsDetail = () => (
  <Show<ProductItem> actions={<ProductsDetailActions />}>
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        flexFlow: 'wrap',
      }}
    >
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="title" />
          <TextField source="contentType" />
          <TextField source="countryOrigin" />
          <LgtvWarningField label="Content prohibition" />
          <TextField source="maxEpisodesAvailable" label="Max episodes" />
          <TextField source="totalEpisodes" label="Total episodes" />
          <RichTextField source="description" />
          <DateField source="lastScannedAt" label="When scanned" />
          <WeTVUrlField label="WeTV URL" />
          <MdlUrlField label="MyDramaList Link" />
        </Tab>
        <Tab label="videos" path="videos">
          <ReferenceManyField
            reference="videos"
            target="product_id"
            label={false}
            perPage={10}
            pagination={<Pagination />}
          >
            <Datagrid
              isRowSelectable={() => false}
              sx={{
                '& .column-index': { width: '1%' },
                '& .column-episode': { width: '1%' },
                '& .column-duration': { width: '10%' },
                '& .column-uploaded_at': {
                  width: '1%',
                },
                '& .column-title': {
                  fontWeight: 'bold',
                  width: '100%',
                },
              }}
            >
              <TextField source="index" label="N" />
              <TextField source="episode" textAlign="right" />
              <DurationField source="duration" label="Duration" />
              <EpisodeTitleUrlField source="title" />
              <DateField source="uploaded_at" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="clips" path="clips">
          <ReferenceManyField
            reference="clips"
            target="product_id"
            label={false}
            perPage={10}
            pagination={<Pagination />}
          >
            <Datagrid
              isRowSelectable={() => false}
              sx={{
                '& .column-index': { width: '1%' },
                '& .column-episode': { width: '1%' },
                '& .column-duration': { width: '10%' },
                '& .column-uploaded_at': {
                  width: '1%',
                },
                '& .column-title': {
                  fontWeight: 'bold',
                  width: '100%',
                },
              }}
            >
              <TextField source="index" label="N" />
              <TextField source="episode" textAlign="right" />
              <DurationField source="duration" label="Duration" />
              <EpisodeTitleUrlField source="title" />
              <DateField source="uploaded_at" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
      <ImageField
        source="posterUrl"
        sx={{
          '& .RaImageField-image': { width: 220, height: 310, margin: '10px' },
        }}
      />
    </div>
  </Show>
);

export default ProductsDetail;
