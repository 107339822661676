import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';

import MdlLinkDialog from './MdlLinkDialog';

export const MdlAppendButton = ({}: { record?: any }) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <>
      <Button color="secondary" variant="contained" onClick={handleClick}>
        Append MDL link
      </Button>
      <MdlLinkDialog open={open} onClose={handleClose} />
    </>
  );
};
