import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { ProductItem } from '../types';

export const EpisodesField = ({}: { label?: string; sortable?: boolean }) => {
  const record = useRecordContext<ProductItem>();
  return (
    <>
      {record?.maxEpisodesAvailable == record?.totalEpisodes ? (
        <span>{`${record?.totalEpisodes}`}</span>
      ) : record?.totalEpisodes === null ? (
        record?.contentType === 'movie' ? (
          <span>{record?.maxEpisodesAvailable}</span>
        ) : (
          <span>{`${record?.maxEpisodesAvailable} / ??`}</span>
        )
      ) : (
        <span>
          {`${record?.maxEpisodesAvailable} / ${record?.totalEpisodes}`}
        </span>
      )}
    </>
  );
};

EpisodesField.defaultProps = {
  textAlign: 'right',
};
