import * as React from 'react';
import { useState } from 'react';
import { Button } from 'react-admin';

import MdlLinkDialog from './MdlLinkDialog';

export const AdditionalMdlButton = ({}: { record?: any }) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button label="Append additonal MDL" onClick={handleClick} />
      <MdlLinkDialog open={open} onClose={handleClose} />
    </>
  );
};
