import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { ProductItem } from '../products/types';

export const MdlUrlField = ({}: { label?: string; sortable?: boolean }) => {
  const record = useRecordContext<ProductItem>();
  if (!record) {
    return null;
  }
  return (
    <>
      {record.mdlUrl ? (
        <a href={`${record.mdlUrl}`} target="_blank">
          {record.mdlTitle}
        </a>
      ) : (
        ''
      )}
    </>
  );
};
