import {
  Datagrid,
  DateField,
  FilterButton,
  List,
  SortButton,
  TextField,
  TextInput,
  TopToolbar,
  SelectInput,
} from 'react-admin';

import { Chip } from '@mui/material';

import { MdlUrlField } from '../../mdl/MdlUrlField';
import { EpisodesField } from '../fields/EpisodesField';
import { ProductItem } from '../types';

const QuickFilter = ({ label }: any) => {
  return <Chip sx={{ marginBottom: 1 }} label={label} />;
};

const filters = [
  <TextInput label="Search" source="q" size="small" alwaysOn />,
  <QuickFilter source="is_followed" label="Followed" defaultValue={true} />,
  <QuickFilter
    source="without_mdl"
    label="Without MDL Link"
    defaultValue={true}
  />,
  <QuickFilter source="upcoming" label="Upcoming only" defaultValue={true} />,
  <QuickFilter source="started" label="Already started" defaultValue={true} />,
  <SelectInput
    source="content_type"
    choices={[
      { id: null, name: 'Any' },
      { id: 'series', name: 'Series' },
      { id: 'movie', name: 'Movies' },
      { id: 'show', name: 'Show' },
      { id: 'kids', name: 'Kids content' },
      { id: 'anime', name: 'Anime' },
      { id: 'documentary', name: 'Documentary' },
      { id: 'special', name: 'Special' },
      { id: 'behind_scenes', name: 'Behind the Scenes' },
    ]}
  />,
  <SelectInput
    source="country_origin"
    choices={[
      { id: null, name: 'Any' },
      { id: 'none', name: 'Not assigned' },
      { id: 'China', name: 'China' },
      { id: 'Hong Kong', name: 'Hong Kong' },
      { id: 'Indonesia', name: 'Indonesia' },
      { id: 'Japan', name: 'Japan' },
      { id: 'Malaysia', name: 'Malaysia' },
      { id: 'South Korea', name: 'South Korea' },
      { id: 'Taiwan', name: 'Taiwan' },
      { id: 'Thailand', name: 'Thailand' },
      { id: 'USA', name: 'USA' },
    ]}
  />,
];

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <SortButton fields={['title', 'latest']} />
  </TopToolbar>
);

const ProductsList = () => (
  <List<ProductItem>
    filters={filters}
    actions={<ListActions />}
    perPage={25}
    sort={{ field: 'latest', order: 'ASC' }}
  >
    <Datagrid rowClick={'show'}>
      <TextField source="title" />
      <TextField source="contentType" sortable={false} />
      <MdlUrlField label="MDL" sortable={false} />
      <TextField source="countryOrigin" sortable={false} />
      <EpisodesField label="Episodes" sortable={false} />
      <TextField
        source="mdlEpisodesCount"
        label="MDL Episodes"
        sortable={false}
      />
      <DateField source="lastScannedAt" label="When scanned" sortable={false} />
    </Datagrid>
  </List>
);

export default ProductsList;
