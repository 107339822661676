import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { MdlItemInfo } from './types';
import { Labeled, ImageField } from 'react-admin';

type MdlItemCardProps = {
  info: MdlItemInfo;
  onSelect?: (info: MdlItemInfo) => void;
};

const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export default function MdlItemCard({ info, onSelect }: MdlItemCardProps) {
  const handleOpenMdlClick = () => {
    openInNewTab(info.url);
  };
  const handleSelectClick = () => {
    if (onSelect && typeof onSelect == 'function') {
      onSelect(info);
    }
  };
  return (
    <Card>
      <CardActionArea onClick={handleOpenMdlClick}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Labeled label="WeTV poster">
            <ImageField
              source="posterUrl"
              sx={{
                '& .RaImageField-image': {
                  width: 220,
                  height: 310,
                  margin: '10px',
                },
              }}
            />
          </Labeled>
          <Labeled label="MDL poster">
            <CardMedia
              component="img"
              // height="320"
              image={info.posterUrl}
              alt="No poster"
              sx={{
                width: 220,
                height: 310,
                margin: '10px',
              }}
            />
          </Labeled>
        </div>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {info.productTitle} ({info.type})
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {info.nativeTitle}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={handleSelectClick}>
          Select this
        </Button>
      </CardActions>
    </Card>
  );
}
