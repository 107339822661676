import React, { useEffect } from 'react';
import { useSafeSetState, useLogin } from 'react-admin';

import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CircularProgress from '@mui/material/CircularProgress';

type LoginProps = {
  redirectTo?: string;
};

const LoginButton: React.FunctionComponent<LoginProps> = ({}: LoginProps) => {
  // const classes = useStyles();
  const login = useLogin();
  const [loading, setLoading] = useSafeSetState(false);

  const handleLogin = (state?: string) => {
    setLoading(true);
    login({ state }).catch(() => {});
  };

  const handleRedirect = () => {
    setLoading(true);
    login({});
  };

  // eslint-disable
  useEffect(() => {
    const { searchParams } = new URL(window.location.href);
    console.log(`Current uri: ${searchParams}`);
    const state = searchParams.get('state');

    if (!!state) {
      console.log(`STATE DETECTED: ${state}`);
      handleLogin(state);
    }
    // eslint-disable-next-line
  }, []);
  // eslint-enable

  return (
    <CardActions style={{ justifyContent: 'center' }}>
      <Button
        // {...rest}
        onClick={handleRedirect}
        variant="contained"
        color="primary"
        disabled={loading}
        // className={classes.button}
      >
        {loading && (
          <CircularProgress
            //   className={classes.icon}
            size={18}
            thickness={2}
          />
        )}
        Sign in using Discord
      </Button>
    </CardActions>
  );
};

LoginButton.defaultProps = {
  redirectTo: '/',
};

export default LoginButton;
