import * as React from 'react';
import { useRecordContext } from 'react-admin';

export const WeTVUrlField = ({}: { label: string }) => {
  const record: any = useRecordContext();
  return (
    <a href={`https://wetv.vip/en/play/${record.id}`} target="_blank">
      WeTV Link
    </a>
  );
};
