import * as React from 'react';
import { useRecordContext } from 'react-admin';

export const EpisodeTitleUrlField = ({}: {
  label?: string;
  source?: string;
  sortable?: boolean;
}) => {
  const record: any = useRecordContext();
  if (!record || !record?.duration) {
    return null;
  }
  return (
    <a
      href={`https://wetv.vip/en/play/${record?.product_id}/${record?.video_id}`}
      target="_blank"
    >
      {record?.title}
    </a>
  );
};

// EpisodesField.defaultProps = {
//   textAlign: 'right',
// };
